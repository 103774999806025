<template>
  <section id="dashboard-ecommerce">
    <b-card>
      <b-card-text>
        <b-row>
          <b-col cols="12" md="9">
            <GmapMap
              :center="center"
              :zoom="12"
              map-type-id="terrain"
              style="width: 100%; height: 1000px"
            >
              <GmapInfoWindow
                :options="infoOptions"
                :position="infoWindowPos"
                :opened="infoWinOpen"
                @closeclick="infoWinOpen = false"
              />

              <GmapMarker
                v-for="(item, index) in items"
                :key="index"
                :title="item.title"
                :position="item.marker"
                :icon="{ url: require(`@/assets/svg/${item.icon}`) }"
                @click="onToggleInfo(item, index)"
              />
            </GmapMap>
          </b-col>
          <b-col class="" cols="12" md="3">
            <b-card>
              <b-row>
                <b-col>
                  <b-form-group label="Search">
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="SearchIcon" />
                      </b-input-group-prepend>
                      <b-form-input @change="(v) => (keywords = v)" />
                      <b-input-group-append is-text class="cursor-pointer">
                        <feather-icon
                          @click="toFilter = !toFilter"
                          icon="FilterIcon"
                          size="16"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="toFilter">
                <b-col>
                  <b-form-group label="Date From">
                    <b-form-input
                      type="date"
                      v-model="dateFrom"
                      placeholder="Date From"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Date To">
                    <b-form-input
                      type="date"
                      v-model="dateTo"
                      placeholder="Date To"
                      :class="{ 'border-danger': errDateRange }"
                    />

                    <small class="text-danger" v-if="errDateRange">
                      <strong>Date To</strong> must not be less than
                      <strong>Date From</strong>
                    </small>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="toFilter">
                <b-col>
                  <b-form-group label="Job Status" label-for="v-job_status">
                    <v-select
                      id="v-job_status"
                      v-model="jobStatus"
                      :options="options"
                      :clearable="false"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <JobCard
                    v-for="(item, index) in dataSource"
                    :key="index"
                    :data-source="item"
                    :highlight="selected"
                    @on-save-job="(v) => onSaveJob(v)"
                    @on-center="(v) => onCenter(v)"
                  />
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-pagination
                    align="right"
                    v-model="currentPage"
                    :total-rows="totalCount"
                    :per-page="5"
                    prev-text="Prev"
                    next-text="Next"
                    first-number
                    last-number
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
  </section>
</template>

<script>
import { queryParameters } from '@/schema';
// @todo change from utils classes to global-function
import { unixToDisplayDateTime } from '@/utils/classes/dates';
import jobOrdersApi from '@api/joborder_headers';
import axios from '@axios';

export default {
  name: 'MapPage',
  components: {},
  data: () => ({
    center: { lat: 1.29027, lng: 103.851959 },
    items: [],
    infoWindowPos: null,
    infoWinOpen: false,
    currentMidx: null,
    dateFrom: null,
    dateTo: null,

    infoOptions: {
      content: '',
      //optional: offset infowindow so it visually sits nicely on top of our marker
      pixelOffset: {
        width: 0,
        height: -50,
      },
      maxWidth: 1020,
      maxHeight: 320,
    },

    oneMapUrl: `https://www.onemap.gov.sg/api/common/elastic/search?returnGeom=Y&getAddrDetails=Y&searchVal`,
    title: 'Map',

    jobStatus: 'all',
    keywords: null,
    currentPage: 1,
    totalCount: 0,
    dataSource: [],
    options: [
      'all',
      'new',
      'accepted',
      'work in progress',
      'paused',
      'done',
      'completed',
    ],
    toFilter: true,
    errDateRange: false,
    selected: '',
  }),
  watch: {
    currentPage(v) {
      this.infoWinOpen = false;
      this.selected = '';
      this.loadJobOrders(v);
    },
    keywords(v) {
      this.loadJobOrders(1);
    },
    jobStatus(v) {
      this.loadJobOrders();
    },
    dateFrom(v) {
      const from = new Date(v);
      const to = new Date(this.dateTo);

      this.errDateRange = from > to;

      this.loadJobOrders();
    },
    dateTo(v) {
      const from = new Date(this.dateFrom);
      const to = new Date(v);

      this.errDateRange = from > to;

      this.loadJobOrders();
    },
  },
  mounted() {
    this.dateFrom = this.inputDateToLocale();
    this.dateTo = this.inputDateToLocale();
  },
  methods: {
    loadJobOrders(current = 1) {
      this.items = [];
      this.dataSource = [];

      const params = {
        sort: queryParameters.sort,
        page: { size: 5, after: current },
        status: queryParameters.status,
        filterExt: '',
      };

      if (this.keywords) {
        params.filter = this.keywords;
      }

      if (this.jobStatus !== 'all') {
        let job_status = '';

        if (this.jobStatus === 'new') {
          job_status = '0';
        }

        if (this.jobStatus === 'accepted') {
          job_status = '1';
        }

        if (this.jobStatus === 'work in progress') {
          job_status = '2';
        }

        if (this.jobStatus === 'paused') {
          job_status = '3';
        }

        if (this.jobStatus === 'done') {
          job_status = '4';
        }

        if (this.jobStatus === 'completed') {
          job_status = '5';
        }

        params.filterExt = `job_status=${job_status}`;
      }

      if (this.dateFrom && this.dateTo) {
        const { dateFrom, dateTo } = this;
        const from = `${dateFrom} 00:00:00.000 +0800`;
        const to = `${dateTo} 23:59:59.000 +0800`;

        if (params.filterExt !== '') {
          params.filterExt += ',';
        }

        params.filterExt += `from_to=${from}|${to}`;
      }

      jobOrdersApi
        .list(params)
        .then(({ data, meta }) => {
          if (data.length > 0) {
            const items = data.map((x, i) => {
              const { job_eta, address } = x;

              x.preview_job_eta = unixToDisplayDateTime(job_eta);
              this.onSearchAddress(address, i, x);
              const markerInfo = this.onSetMarker(x);
              x.marker = { marker: markerInfo, index: i };

              return x;
            });

            this.dataSource = items;
          }

          this.totalCount = meta.page.total;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSearchAddress(address, index, item) {
      axios
        .get(`${this.oneMapUrl}=${address}`)
        .then(({ data }) => {
          if (data) {
            const { results } = data;

            if (results) {
              const [result] = results;
              item = {
                ...item,
                latitude: result.LATITUDE,
                longitude: result.LONGITUDE,
              };

              const markerInfo = this.onSetMarker(item);

              this.items.push(markerInfo);
            }
          }
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSaveJob(v) {
      this.dataSource = [];
      if (v) {
        this.loadJobOrders();
      }
    },
    onCenter(v) {
      const { center, marker } = v;
      this.center = center;
      this.onToggleInfo(marker.marker, marker.index);
    },
    onToggleInfo(item, index) {
      this.infoWindowPos = item.marker;
      this.infoOptions.content = item.info;
      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == index) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = index;
      }

      this.selected = this.infoWinOpen ? item.uuid : '';
    },
    onClick() {
      const ticketNo = document.querySelector('#ticketNoID');
      console.log(ticketNo);
    },
    onSetMarker(item) {
      const {
        uuid,
        job_status,
        job_type,
        ticket_no,
        company_name,
        address,
        job_eta,
        latitude,
        longitude,
      } = item;

      const preview_job_eta = unixToDisplayDateTime(job_eta);

      let icon = '';
      let status = 'New';
      if (job_status === '0') {
        icon = 'map-pin-primary.svg';
      }

      if (job_status === '1') {
        icon = 'map-pin-info.svg';
        status = 'Accepted';
      }

      if (job_status === '2') {
        icon = 'map-pin-warning.svg';
        status = 'Work in Progress';
      }

      if (job_status === '3') {
        icon = 'map-pin-danger.svg';
        status = 'Pause';
      }

      if (job_status === '4') {
        icon = 'map-pin-success.svg';
        status = 'Done';
      }

      if (job_status === '5') {
        icon = 'map-pin-secondary.svg';
        status = 'Completed';
      }

      const markerInfo = {
        uuid,
        marker: {
          lat: parseFloat(latitude),
          lng: parseFloat(longitude),
        },
        icon,
        title: job_type,
        info: `
          <strong>${ticket_no} (${job_type})</strong>
          <br />
          ${company_name} - ${preview_job_eta}
          <br />
          ${address}
          <br />
          Status: <strong>${status}</strong>
        `,
      };

      return markerInfo;
    },
  },
};
</script>

<style>
.card {
  margin-bottom: 1rem !important;
}
</style>
